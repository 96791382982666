<template>
  <resize-observer
    class="overflow-y-hidden"
    @resize="reRender"
  >
    <v-list-item-group
      class="full-height"
      :color="color"
      :value="value"
      @hook:updated="updateSelectedValue()"
      ref="group"
    >
      <v-virtual-scroll
        v-show="items.length"
        v-scroll.self="onScroll"
        v-resize="reRender"
        bench=5
        class="virtual-scroll"
        :class="{'overflow-x-hidden': typeof width === 'undefined'}"
        :items="items"
        :item-height="itemHeight"
        height="calc(100% + 1px)"
        :min-width="minWidth"
        :width="width"
        ref="virtual-scroll"
        v-mutate.child.char.sub="computeDimensions"
        @hook:mounted="watch"
        @hook:beforeDestroy="unwatch"
      >
        <template v-slot:default="{ item, index }">
          <v-list-item
            :style="'height: '+itemHeight+'px'"
            :key="itemKey ? item[itemKey] : index"
            :value="itemKey ? item[itemKey] : index"
            @click="$emit('click:item',  {$event, item, index})"
            class="text-no-wrap"
          >
            <slot
              name="prepend-action"
              :item="item"
            />
            <v-list-item-content>
              <slot :item="item">
                <div v-if="typeof item === 'string'">{{item}}</div>
                <div v-else-if="item.static">{{item[itemName]}}</div>
                <!-- <div v-else-if="!item.id">{{$t('t.None')}}</div> ON LAISSE LES DOC REFS GERER LES AUCUNS POUR AVOIR AUTOMATIQUEMENT L'ICONE DE LA CATEGORIE-->
                <document-picker-list-item-ref
                  v-else
                  :item="item"
                  :show-icon="showIcon"
                  :show-detail="showDetail"
                  :mixed="mixed"
                  :iconProps="_iconProps"
                  :can-edit-column="canEditColumn"
                  :user-ref-props="{ isForSelection: true}"
                />
              </slot>
            </v-list-item-content>
            <slot
              name="append-action"
              :item="item"
            />
          </v-list-item>
        </template>
      </v-virtual-scroll>
      <v-list-item v-show="!loading && !items.length">
        <v-list-item-content>
          <slot name="empty">
            {{$t('t.NoResult')}}
          </slot>
        </v-list-item-content>
      </v-list-item>
      <v-list-item v-show="loading">
        <v-list-item-content>
          <slot name="loading">
            {{$t('t.Loading')}}
          </slot>
        </v-list-item-content>
      </v-list-item>
    </v-list-item-group>
  </resize-observer>
</template>

<script>
import Debug from '@/debugger'
const debug = Debug('cot:components:suggestion-list')

export default {
  components: {
    DocumentPickerListItemRef: () => import('@/components/document-picker-list-item-ref'),
    ResizeObserver: () => import('@/components/resize-observer')
  },
  data () {
    return {
      computeDimensions: this.lodash.debounce(this.debouncedComputeDimensions, 50),
      itemHeight: 48,
      isVisible: false,
      scrollTracker: 0,
      unwatch: () => { },
      widestItem: 0
    }
  },
  computed: {
    _iconProps () {
      const _ = Object.assign({
        xsmall: false,
        small: false,
        normal: false,
        large: false,
        xlarge: false
      }, this.iconProps)

      return _
    },
    minWidth () {
      return typeof this.width === 'undefined' ? this.widestItem + 'px' : undefined
    }
  },
  methods: {
    // Determine the widest and the highest element ever displayed in the item list
    debouncedComputeDimensions () {
      let width = this.widestItem
      let height = this.itemHeight

      this.$refs['virtual-scroll']?.$el.querySelectorAll('.v-virtual-scroll__item').forEach(item => {
        width = Math.max(width, item.getBoundingClientRect().width)
        height = Math.max(height, item.querySelector('.v-list-item__content').getBoundingClientRect().height)
      })

      this.widestItem = width
      this.itemHeight = height
      this.$emit('resized')
    },
    emitEndReached (count) {
      if (count > 0) {
        debug(`End reached, ${count} items`)
        this.$emit('end-reached', count)
      }
    },
    // Keep the scroll position, this is usefull if this component can be shown/hidden while still mounted
    onScroll (event) {
      this.scrollTracker = event.target.scrollTop
    },
    // This method will reRender the virtualized items, useful for window resizing or items change
    reRender () {
      const visibility = this.$refs['virtual-scroll']?.$el?.checkVisibility()
      if (visibility && !this.isVisible) {
        debug('Scroll position reseted')
        this.$refs['virtual-scroll'].$el.scroll(0, this.scrollTracker)
        this.computeDimensions()
      }
      this.isVisible = visibility
      this.$refs?.['virtual-scroll']?.onScroll()
    },
    resetScroll () {
      this.$refs['virtual-scroll']?.$el?.scroll(0, 0)
    },
    updateSelectedValue () {
      const group = this.$refs.group
      if (group.value && group.internalValue !== group.value) {
        this.$refs.group.updateInternalValue(this.$refs.group.value)
      }
    },
    // This watcher allow us to detect when the last item is scrolled into view
    watch () {
      this.unwatch = this.$watch(
        () => this.$refs['virtual-scroll'].last,
        v => {
          if (v >= this.items.length) {
            // We emit the count of the item, start from 1
            this.emitEndReached(v)
          }
        },
        { immediate: true }
      )
    }
  },
  watch: {
    items () {
      this.resetScroll()
      this.reRender()

      if (this.$refs['virtual-scroll']?.last >= this.items.length) {
        this.emitEndReached(this.$refs['virtual-scroll'].last)
      }
    }
  },
  props: {
    canEditColumn: Boolean,
    color: String,
    iconProps: Object,
    itemKey: String,
    itemName: {
      default: 'name',
      type: String
    },
    items: {
      default: () => [],
      type: Array
    },
    loading: {
      default: false,
      type: Boolean
    },
    mixed: Boolean,
    showIcon: Boolean,
    showDetail: Boolean,
    value: [String, Number],
    width: String
  }
}
</script>

<style lang="stylus" scoped>
.full-height
  height 100%

// This will correctly apply width when there is a scrollbar
.virtual-scroll
  scrollbar-gutter stable both-edges

  >>> .v-virtual-scroll__container
    min-width inherit

// This will allow to properly compute virtual items width and height with debouncedComputeDimensions
>>> .v-virtual-scroll__item
  min-width fit-content
</style>
