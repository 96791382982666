import { render, staticRenderFns } from "./suggestion-list.vue?vue&type=template&id=5a808263&scoped=true&"
import script from "./suggestion-list.vue?vue&type=script&lang=js&"
export * from "./suggestion-list.vue?vue&type=script&lang=js&"
import style0 from "./suggestion-list.vue?vue&type=style&index=0&id=5a808263&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5a808263",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VVirtualScroll } from 'vuetify/lib/components/VVirtualScroll';
installComponents(component, {VListItem,VListItemContent,VListItemGroup,VVirtualScroll})


/* vuetify-loader */
import installDirectives from "!../../../../node_modules/vuetify-loader/lib/runtime/installDirectives.js"
import Mutate from 'vuetify/lib/directives/mutate'
import Resize from 'vuetify/lib/directives/resize'
import Scroll from 'vuetify/lib/directives/scroll'
installDirectives(component, {Mutate,Resize,Scroll})
